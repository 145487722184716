<template>
  <div>
    <v-btn-toggle v-model="typeLocal" class="d-flex justify-center" color="primary" tile group mandatory>
      <v-btn value="boulder">Bouldering</v-btn>
      <v-btn value="route">Climbing</v-btn>
    </v-btn-toggle>
    <div class="d-flex justify-center tl-grades-chart">
      <div class="d-flex flex-column grades-column points">
        <div class="name">{{ $t('dashboard.score.points') }}</div>
        <div v-for="value in points" :key="value" class="grade-item" :style="gradeStyle(value)">
          <div class="grade-text">{{ value * 100 }}</div>
        </div>
      </div>
      <div
        v-for="system in systems"
        :key="system.name"
        class="d-flex flex-column grades-column"
        :class="{ 'current-system': system.name == currentSystemName }"
      >
        <div class="name text-no-wrap">{{ system.name }}</div>
        <div class="current-grade" :style="{ height: valToPercent(currentGrade) }"></div>
        <div v-for="grade in system.data" :key="grade.value" class="grade-item" :style="gradeStyle(grade.value)">
          <div class="grade-text" :class="{ 'is-smaller': grade.value < currentGrade }" v-html="grade.name"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Grade from '@/services/grades/Grade'

export default {
  props: {
    type: { type: String, default: 'boulder' },
    currentGrade: { type: Number, default: 10 },
  },
  data: () => ({
    typeLocal: 'boulder',
  }),
  computed: {
    systems() {
      const systemsArr = Object.keys(Grade.systems).map(key => Grade.systems[key]) // Object.values requires polyfill...
      return systemsArr.filter(system => system.types.includes(this.typeLocal))
    },
    points() {
      return Grade.systems['french'].data.map(d => d.value)
    },
    currentSystemName() {
      return Grade.system(this.typeLocal).name
    },
  },
  created() {
    this.typeLocal = this.type
  },
  methods: {
    gradeStyle(value) {
      let style = {
        top: this.valToPercent(value),
      }
      if (value % 1 === 0) {
        style['border-top-width'] = '2px'
        style['font-weight'] = 'bold'
      }
      return style
    },
    valToPercent(val) {
      const minVal = this.points[0]
      const maxVal = this.points[this.points.length - 1] + 0.12
      let percent = ((val - minVal) / (maxVal - minVal)) * 100
      return (percent > 100 ? 100 : percent) + '%'
    },
  },
}
</script>

<style lang="sass" scoped>
.tl-grades-chart
  .grades-column
    position: relative
    height: 1000px
    width: 50px
    margin-top: 80px
    background-color: var(--v-grey-base)
    color: white
    .name
      text-align: right
      padding-right: 16px
      transform-origin: right top
      transform: rotate(60deg) translate(-10px, 6px)
      width: 100px
      position: absolute
      right: 0
      color: black
    & + .grades-column
      border-left: 1px solid white
    .grade-item
      position: absolute
      width: 100%
      text-align: center
      border-top: 1px solid white
      // Only for first item
      margin-top: 10px
      .grade-text
        position: absolute
        left: 50%
        padding: 0 4px
        transform: translate(-50%, -50%)
        background-color: var(--v-grey-base)
        border-radius: 4px
        white-space: nowrap
      & ~ .grade-item
        // For all but first item
        margin-top: 0

    &.points
      background-color: white
      color: black
      width: 45px
      .grade-item
        border-top-color: black
        .grade-text
          left: 0
          transform: translateY(-50%)
          background-color: white

    &.current-system
      background-color: var(--v-primary-lighten2)
      .grade-item
        .grade-text
          background-color: var(--v-primary-lighten2)
          &.is-smaller
            background-color: var(--v-primary-base)
      .current-grade
        position: absolute
        width: 100%
        background-color: var(--v-primary-base)
</style>
